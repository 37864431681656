<template>
    <div>
        <router-view></router-view>

        <b-overlay :show="loading" no-wrap z-index="99999"></b-overlay>
        <b-toaster name="toaster" class="b-toaster b-toaster-bottom-right"></b-toaster>
        <b-toaster name="toaster2" class="b-toaster b-toaster-top-center"></b-toaster>

        <custom-slot id="app_container" :base="getThis()"></custom-slot>

        <div v-if="!hideImpersonate && impersonate" class="card bg-warning mb-1 impersonate-warning">
            <div class="card-body m-0 p-1">
                <b-row>
                    <b-col cols="6">
                        <div class=" text-uppercase font-weight-bold">
                            <font-awesome-icon class="mr-2" icon="info"/>
                            In to you account now logged Admin
                        </div>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <b-button @click="closeImpersonateWarning" size="sm">
                            <font-awesome-icon icon="times"/>
                        </b-button>
                    </b-col>
                </b-row>

            </div>
        </div>

        <div v-if="!hideConnectionDisabled && connectionDisabled.length"
             class="card bg-warning mb-1 impersonate-warning">
            <div class="card-body m-0 p-1">
                <b-row>
                    <b-col cols="6">
                        <div class=" text-uppercase font-weight-bold">
                            <font-awesome-icon class="mr-2" icon="info"/>
                            External service disabled due to errors. Please contact Admin to solve this problem.
                            <div v-for="connection in connectionDisabled" :key="connection.id">
                                {{ connection.type }} ({{ connection.message }}) {{ connection.disabled_at }}
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <b-button @click="closeConnectionDisabledWarning" size="sm">
                            <font-awesome-icon icon="times"/>
                        </b-button>
                    </b-col>
                </b-row>

            </div>
        </div>

        <b-toast :title="$t('common.label.jobs_group.jobs_in_background')"
                 v-model="jobsToast"
                 no-auto-hide
                 toaster="toaster2"
                 variant="primary"
        >
            <b-row>
                <b-col cols="2" class="text-center">
                    <b-spinner v-if="jobsLoading"
                               variant="primary"
                    ></b-spinner>
                    <font-awesome-icon v-else class="text-success" size="lg" icon="check"/>
                </b-col>
                <b-col cols="5">
                    <div>
                        <font-awesome-icon class="mr-2" icon="play-circle"/>
                        {{ $t('common.label.jobs_group.total') }}: <strong>{{ jobsByGroupResult.jobs }}</strong></div>
                    <div>
                        <font-awesome-icon class="mr-2 text-warning" icon="info-circle"/>
                        {{ $t('common.label.jobs_group.in_progress') }}:
                        <strong>{{ jobsByGroupResult.progress }}</strong>
                    </div>
                    <div>
                        <font-awesome-icon class="mr-2 text-success" icon="info-circle"/>
                        {{ $t('common.label.jobs_group.success') }}:
                        <strong>{{ jobsByGroupResult.success }}</strong>
                    </div>
                </b-col>
                <b-col cols="5">
                    <div>
                        <font-awesome-icon class="mr-2 text-primary" icon="check-circle"/>
                        {{ $t('common.label.jobs_group.done') }}: <strong>{{ jobsByGroupResult.done }}</strong>
                    </div>
                    <div>
                        <b-icon :icon="'x-circle'" class="mr-2 text-danger"></b-icon>
                        {{ $t('common.label.jobs_group.failed') }}: <strong>{{ jobsByGroupResult.failed }}</strong>
                    </div>
                </b-col>
            </b-row>
            <b-button v-if="!jobsLoading"
                      @click="()=>{openModal('job-info-modal', {job_group: jobsGroup});this.jobsToast=false}"
                      variant="primary" block>{{ $t('common.label.jobs_group.more_info') }}
            </b-button>
        </b-toast>

        <component v-for="(modal,index) in renderedModals"
                   :key="index"
                   :ref="modal"
                   v-bind:is="modalsMap[modal]"
        ></component>

        <vue-confirm-dialog></vue-confirm-dialog>
    </div>
</template>

<script>
import i18n from "@/i18n";
import {localeChanged} from "vee-validate";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    name: 'App',
    data() {
        return {
            loading: true,
            jobsToast: false,
            jobsGroup: null,
            jobsByGroupResult: {},
            jobsLoading: true,
            renderedModals: [],
            interval: null,
            jobsInterval: null,
            impersonateInterval: null,
            impersonate: false,
            connectionDisabled: [],
            openModalCallback: null,
            hideImpersonate: false,
            hideConnectionDisabled: false,
            initialValues: {},
            modalsMap: {
                'channel-article-modal': () => import('./modals/article/ChannelArticleModal'),
                'get-amazon-products-modal': () => import('./modals/article/GetAmazonProductsModal'),
                'process-modal': () => import('./modals/common/ProcessesModal'),
                'todo-modal': () => import('./modals/common/TodoModal'),
                'pre-todo-modal': () => import('./modals/common/PreTodoModal'),
                'customer-report-modal': () => import('./modals/customer/CustomerReportModal'),
                'kva-modal': () => import('./modals/common/KvaModal'),
                'import-modal': () => import('./modals/common/ImportModal'),
                'export-modal': () => import('./modals/common/ExportModal'),
                'columns-modal': () => import('./modals/common/ColumnsModal'),
                'columns-new-modal': () => import('./modals/common/ColumnsNewModal'),
                'choose-paper-format-modal': () => import('./modals/sales/ChoosePaperFormatModal'),
                'document-prepare-modal': () => import('./modals/offering/DocumentPrepareModal'),
                'article-modal': () => import('./modals/article/ArticleModal'),
                'room-article-modal': () => import('./modals/article/RoomArticleModal'),
                'service-article-modal': () => import('./modals/article/ServiceArticleModal'),
                'deposit-refund-modal': () => import('./modals/invoice/DepositRefundModal'),
                'insurance-modal': () => import('./modals/invoice/InsuranceModal'),
                'paid-modal': () => import('./modals/invoice/PaidModal'),
                'invoice-cancel-modal': () => import('./modals/invoice/CancelModal'),
                'map-article-modal': () => import('./modals/article/MapArticleModal'),
                'change-attribute-modal': () => import('./modals/massupdate/ChangeAttributeModal'),
                'status-modal': () => import('./modals/settings/StatusModal'),
                'entity-status-modal': () => import('./modals/settings/EntityStatusModal'),
                'paymentmethod-modal': () => import('./modals/settings/PaymentmethodModal'),
                'todos-action-modal': () => import('./modals/settings/TodosActionModal'),
                'todos-sub-action-modal': () => import('./modals/settings/TodosSubActionModal.vue'),
                'sales-attachment-modal': () => import('./modals/sales/AttachmentModal'),
                'role-modal': () => import('./modals/settings/RoleModal'),
                'user-modal': () => import('./modals/user/UserModal'),
                'password-modal': () => import('./modals/user/PasswordModal'),
                'channel-modal': () => import('./modals/settings/ChannelModal'),
                'channel-settings-modal': () => import('./modals/settings/ChannelSettingsModal'),
                'channel-sync-modal': () => import('./modals/settings/ChannelSyncModal'),
                'store-modal': () => import('./modals/settings/StoreModal'),
                'new-workflow-modal': () => import('./modals/settings/NewWorkflowModal'),
                'shipping-rule-modal': () => import('./modals/settings/ShippingRuleModal'),
                'transfer-modal': () => import('./modals/booking/TransferModal'),
                'module-modal': () => import('./modals/common/ModuleModal'),
                'hotel-modal': () => import('./modals/booking/HotelModal'),
                'append-article-modal': () => import('./modals/article/AppendArticleModal'),
                'assign-network-modal': () => import('./modals/article/AssignNetworkModal'),
                'change-store-attribute-modal': () => import('./modals/article/ChangeStoreAttributeModal'),
                'hotel-room-article-modal': () => import('./modals/article/HotelRoomArticleModal'),
                'update-store-article-price': () => import('./modals/article/UpdateStoreArticlePriceModal'),
                'customer-reports': () => import('./tables/CustomerReports'),
                'customer-modal': () => import('./modals/customer/CustomerModal'),
                'customer-contract-modal': () => import('./modals/customer/CustomerContractModal'),
                'contract-modal': () => import('./modals/customer/ContractModal'),
                'news-modal': () => import('./modals/user/NewsModal'),
                'show-news-modal': () => import('./modals/user/ShowNewsModal'),
                'contact-person-form': () => import('./modals/customer/ContactPersonForm'),
                'project-form': () => import('./modals/offering/ProjectForm'),
                'offering-change-customer': () => import('./modals/offering/ChangeCustomer'),
                'incoming-invoices-modal': () => import('./modals/invoice/IncomingInvoicesModal'),
                'stock-modal': () => import('./modals/article/StockModal'),
                'stock-push-modal': () => import('./modals/article/StockPushModal'),
                'stock-move-modal': () => import('./modals/article/StockMoveModal'),
                'stock-delete-modal': () => import('./modals/article/StockDeleteModal'),
                'stock-return-modal': () => import('./modals/article/StockReturnModal'),
                'supply-modal': () => import('./modals/article/SupplyModal'),
                'supply-received-modal': () => import('./modals/article/SupplyReceivedModal'),
                'supply-cancel-modal': () => import('./modals/article/SupplyCancelModal'),
                'json-modal': () => import('./modals/common/JsonModal'),
                'customer-category-modal': () => import('./modals/settings/CustomerCategoryModal'),
                'attribute-modal': () => import('./modals/settings/AttributeModal'),
                'attribute-set-modal': () => import('./modals/settings/AttributeSetModal'),
                'attribute-option-group-modal': () => import('./modals/settings/AttributeOptionGroupModal'),
                'header-modal': () => import('./modals/settings/HeaderModal'),
                'footer-modal': () => import('./modals/settings/FooterModal'),
                'email-template-modal': () => import('./modals/settings/EmailTemplateModal'),
                'email-block-modal': () => import('./modals/settings/EmailBlockModal'),
                'news-template-modal': () => import('./modals/settings/NewsTemplateModal'),
                'lager-type-modal': () => import('./modals/settings/LagerTypeModal'),
                'warehouse-modal': () => import('./modals/settings/warehouse/WarehouseModal'),
                'vat-modal': () => import('./modals/settings/VatModal'),
                'profile-modal': () => import('./modals/settings/ProfileModal'),
                'test-email-modal': () => import('./modals/settings/TestEmailModal'),
                'email-modal': () => import('./modals/common/EmailModal'),
                'emails-modal': () => import('./modals/common/EmailsModal'),
                'show-email-message-modal': () => import('./modals/common/ShowEmailMessageModal'),
                'language-modal': () => import('./modals/settings/LanguageModal'),
                'offering-modal': () => import('./modals/offering/OfferingModal'),
                // 'supply-offering-modal': () => import('./modals/offering/SupplyOfferingModal'),
                'shelves-modal': () => import('./modals/settings/warehouse/ShelvesModal'),
                'shelve-modal': () => import('./modals/settings/warehouse/ShelveModal'),
                'generate-racks-modal': () => import('./modals/settings/warehouse/GenerateRacksModal'),
                'generate-shelves-modal': () => import('./modals/settings/warehouse/GenerateShelvesModal'),
                'offering-address-modal': () => import('./modals/offering/OfferingAddressModal'),
                'offering-position-modal': () => import('./modals/offering/OfferingPositionModal'),
                'upload-file-modal': () => import('./modals/common/UploadFileModal'),
                'kbkonto-modal': () => import('./modals/settings/KbkontoModal'),
                'kbbuchung-modal': () => import('./modals/settings/KbbuchungModal'),
                'category-modal': () => import('./modals/settings/CategoryModal'),
                'scan-and-print-modal': () => import('./modals/sales/ScanAndPrintModal'),
                'search-offering-modal': () => import('./modals/invoice/SearchOfferingModal'),
                'scan-modal': () => import('./modals/sales/ScanModal'),
                'return-position-modal': () => import('./modals/offering/ReturnPositionModal'),
                'custom-field-modal': () => import('./modals/common/CustomFieldModal'),
                'cash-invoice-modal': () => import('./modals/invoice/CashInvoiceModal'),
                'bank-transfers': () => import('./modals/invoice/BankTransfers'),
                'transfer-assign-modal': () => import('./modals/invoice/TransferAssign'),
                'assign-invoice-modal': () => import('./modals/invoice/AssignInvoiceModal'),
                'assign-incoming-invoice-modal': () => import('./modals/invoice/AssignIncomingInvoiceModal'),
                'assign-bank-transfer-modal': () => import('./modals/invoice/AssignBankTransferModal'),
                'assign-credit-bank-transfer-modal': () => import('./modals/invoice/AssignCreditBankTransferModal'),
                'manual-bank-transfer-modal': () => import('./modals/invoice/ManualBankTransfer'),
                'transaction-from-incoming-modal': () => import('./modals/invoice/TransactionFromIncomingModal'),
                'mass-stock-assign-modal': () => import('./modals/article/MassStockAssign'),
                'import-history-modal': () => import('./modals/common/ImportsHistoryModal'),
                'job-info-modal': () => import('./modals/common/JobInfoModal'),
                'workflow-selection-modal': () => import('./modals/offering/WorkflowSelectionModal'),
                'duplicate-offering-modal': () => import('./modals/offering/DuplicateOfferingModal'),
                'customer-document-modal': () => import('./modals/customer/CustomerDocumentModal'),
                'select-customer-modal': () => import('./modals/select/SelectCustomerModal'),
                'select-contact-person-modal': () => import('./modals/select/SelectContactPersonModal'),
                'select-offering-modal': () => import('./modals/select/SelectOfferingModal'),
                'select-contract-modal': () => import('./modals/select/SelectContractModal'),
                'select-customer-contract-modal': () => import('./modals/select/SelectCustomerContractModal'),
                'events-modal': () => import('./modals/common/EventsModal'),
                'event-modal': () => import('./modals/common/EventModal'),
                'save-filter-modal': () => import('./modals/common/SaveFilterModal'),
                'attachments-modal': () => import('./modals/common/AttachmentsModal'),
                'select-supplier-modal': () => import('./modals/offering/SelectSupplier'),
                'supply-stock-push-modal': () => import('./modals/article/SupplyStockPushModal'),
                'workflow-trigger-modal': () => import('./modals/settings/WorkflowTriggerModal'),
                'customer-type-modal': () => import('./modals/settings/CustomerTypeModal'),
                'customer-template': () => import('./modals/customer/CustomerTemplateModal'),
            },
        }
    },
    created() {
        this.interval = window.setInterval(() => {
            this.resize()

            if (this.renderedModals.length) {
                if (this.$refs[this.renderedModals[this.renderedModals.length - 1]][0].modalTitle) {
                    document.title = this.$refs[this.renderedModals[this.renderedModals.length - 1]][0].modalTitle()
                } else {
                    document.title = 'eRIKA - smart digital solution'
                }
            } else {
                document.title = 'eRIKA - smart digital solution'
            }

        }, 700)

        this.axios.interceptors.request.use((config) => {
            if (!config.skip_loading) {
                this.loading = true
            }
            return config;
        }, (error) => {
            console.log('e1', error)
            return Promise.reject(error);
        });

        this.axios.interceptors.response.use((response) => {
            this.loading = false
            return response;
        }, (error) => {
            this.loading = false

            if (error.message == 'Operation canceled') {
                this.$root.showMessage(this.$t('common.toasts.error'), 'Canceled!', 'danger')
                return null
            }
            if (error.response.status == 401) {
                window.location = '/'
            } else if (error.response.status == 422) {
                return Promise.reject(error)
                //Validation error
            } else if (error.response.status == 403) {
                this.$root.showMessage(this.$t('common.toasts.error'), 'Access denied!', 'danger')
                return null
            } else {
                this.$root.showMessage(this.$t('common.toasts.error'), (error.response.data.error ?? (error.text ?? (error.response.statusText ?? 'Unknown error'))), 'danger')
                return Promise.reject(error)
            }
        });
    },
    beforeDestroy() {
        clearInterval(this.interval)
        clearInterval(this.jobsInterval)
        clearInterval(this.impersonateInterval)
        this.interval = null
        this.jobsInterval = null
        this.impersonateInterval = null
    },
    methods: {
        ...mapGetters(['getSidebarShow']),
        ...mapGetters('Jobs', ['getJobsByGroup', 'getImpersonate', 'getExternalConnections']),
        ...mapGetters('CommonData', ['getCommonData']),
        closeImpersonateWarning() {
            this.hideImpersonate = true
        },
        getThis() {
            return this
        },
        closeConnectionDisabledWarning() {
            this.$store.dispatch('Jobs/enableConnections').then(() => {
                this.hideConnectionDisabled = true
            })
        },
        resize() {
            if (this.getCommonData().domain == 'test' || this.getCommonData().domain == 'maintenance') {
                if (document.getElementsByClassName('c-sidebar')[0]) {
                    document.getElementsByClassName('c-sidebar')[0].classList.add('c-sidebar-maintenance')
                }
            }

            let sidebar = document.getElementsByClassName('c-sidebar')[0] || null
            if (!sidebar) {
                return
            }
            let sidebarMargin = window.getComputedStyle(document.getElementsByClassName('c-sidebar')[0]).marginLeft
            let sidebarWidth = window.getComputedStyle(document.getElementsByClassName('c-sidebar')[0]).width


            if (document.getElementsByClassName('right_sidebar').length) {
                var helpWidth = window.getComputedStyle(document.getElementsByClassName('right_sidebar')[0]).width
                helpWidth = Math.ceil(parseFloat(helpWidth))
            } else {
                var helpWidth = 0;
            }


            let bodyWidth = Math.ceil(parseFloat(window.getComputedStyle(document.getElementsByClassName('c-body')[0]).width));


            let header = document.getElementsByClassName('c-header-fixed')[0] || null;
            if (header) {
                let newWidth = document.body.clientWidth
                let newLeft = 0

                if (sidebarMargin == '0px') {
                    newWidth = document.body.clientWidth - Math.ceil(parseFloat(sidebarWidth)) - 1
                    newLeft = parseInt(sidebarWidth)
                }

                if (bodyWidth < document.body.clientWidth) {
                    newWidth = newWidth - helpWidth - 1
                }

                document.getElementsByClassName('c-header-fixed')[0].style.left = newLeft + 'px';
                document.getElementsByClassName('c-header-fixed')[0].style.width = newWidth + 2 + 'px';

                if (bodyWidth > document.body.clientWidth) {
                    newWidth = newWidth - helpWidth - 1
                }

                if (document.querySelector('.content-width')) {
                    document.querySelectorAll('.content-width').forEach((element) => {
                        element.style.width = (newWidth - 12) + 'px'
                    })
                }

                let topHeaderHeight = window.getComputedStyle(document.querySelector('.c-header-fixed')).height || 0
                let headerHeight = 0;
                if (document.querySelector('.zw-page > header')) {
                    document.querySelector('.zw-page > header').style.width = newWidth + 'px';
                    headerHeight = window.getComputedStyle(document.querySelector('.zw-page > header')).height
                }
                if (document.querySelector('.zw-page .table-actions')) {
                    let actionsHeight = window.getComputedStyle(document.querySelector('.zw-page .table-actions')).height

                    document.querySelector('.zw-page .table-actions').style.width = newWidth + 5 + 'px';
                    document.querySelector('.zw-page .table-actions').style.position = 'fixed';
                    document.querySelector('.zw-page .table-actions').style.top = parseInt(headerHeight) + parseInt(topHeaderHeight) + 'px';

                    if (document.querySelector('.zw-page .zw-sticky')) {
                        document.querySelector('.zw-page .zw-sticky').style.paddingTop = actionsHeight;
                    }
                    if (document.querySelector('.zw-sticky .table thead th')) {
                        document.querySelectorAll('.zw-sticky .table thead th').forEach((element) => {
                            element.style.top = parseInt(topHeaderHeight) + parseInt(actionsHeight) + parseInt(headerHeight) - 2 + 'px';
                        })

                        let stickyHeaderHeight = window.getComputedStyle(document.querySelector('.zw-sticky .table thead th')).height
                        document.querySelectorAll('.zw-sticky .table .b-table-top-row th').forEach((element) => {
                            element.style.top = parseInt(topHeaderHeight) + parseInt(actionsHeight) + parseInt(headerHeight) + parseInt(stickyHeaderHeight) - 4 + 'px';
                        })
                    }
                }
            }

        },
        openModal(id, payload, callback = null, options = {}) {
            if (id == 'kva-modal') {
                id = 'offering-modal'
            }

            if (!this.renderedModals.includes(id)) {
                this.renderedModals.push(id)
            } else {
                this.closeModal(this.$refs[id][0].$children[0].$refs['sidebar'])
                this.$nextTick(() => {
                    this.renderedModals.push(id)
                })
            }
            this.openModalCallback = () => {
                this.$refs[id][0].loading = true
                this.$refs[id][0].payload = payload
                this.$refs[id][0].callback = callback
                this.$refs[id][0].$children[0].width = options.width ? options.width : 95 - (2 * this.renderedModals.length)
                this.$refs[id][0].$children[0].name = id
                this.$refs[id][0].$children[0].id = id
                this.$refs[id][0].$children[0].payload = payload
                this.$refs[id][0].$children[0].zIndex = 2000 + this.renderedModals.length
                this.$refs[id][0].$children[0].$refs['sidebar'].localShow = true

                document.body.classList.add("modal-open");

                setTimeout(() => {
                    if (document.querySelectorAll(".modal_open_focus").length) {
                        let elements = document.querySelectorAll(".modal_open_focus")
                        let element = elements[Object.keys(elements)[Object.keys(elements).length - 1]]
                        if (typeof element.focus === 'function') {
                            element.focus()
                        }
                    }
                }, 500);

                this.updateFormValues(id)
            }
        },
        updateFormValues(id) {
            this.$nextTick(() => {
                const intervalId = setInterval(() => {
                    const contentDiv = document.getElementById(id);
                    if (contentDiv && !contentDiv.querySelector('.b-overlay')) {
                        let initialValues = {}
                        const inputs = contentDiv.querySelectorAll('input, textarea, select, div.multiselect__tags-wrap, iframe.cke_wysiwyg_frame');
                        inputs.forEach((input, index) => {
                            let newVal = input.value;
                            if (input.tagName === 'DIV') {
                                const tagElements = input.querySelectorAll('.multiselect__tag');
                                newVal = JSON.stringify(Array.from(tagElements).map(tag => tag.querySelector('span').textContent.trim()));
                            }
                            if (input.tagName === 'IFRAME') {
                                const iframeDoc = input.contentDocument || input.contentWindow.document;
                                newVal = iframeDoc.body.innerText;
                            }
                            if (input.type === 'checkbox') {
                                newVal = input.checked ? 1 : 0;
                            }
                            initialValues[`input${index}`] = newVal;
                        });
                        this.initialValues[id] = initialValues
                        clearInterval(intervalId);
                    }
                }, 1000);
            })
        },
        checkForChanges(id) {
            const inputs = document.getElementById(id).querySelectorAll('input, textarea, select, div.multiselect__tags-wrap, iframe.cke_wysiwyg_frame');

            let hasChanges = false;
            let initialValues = this.initialValues[id];
            let newInitialValues = {}
            inputs.forEach((input, index) => {
                if (typeof initialValues != "undefined" && !this.hasParentWithAnyClass(input, ['b-table-top-row', 'table-info', 'table-actions', 'td_table_selected'])) {
                    let newVal = input.value;
                    if (input.tagName === 'DIV') {
                        const tagElements = input.querySelectorAll('.multiselect__tag');
                        newVal = JSON.stringify(Array.from(tagElements).map(tag => tag.querySelector('span').textContent.trim()));
                    }
                    if (input.tagName === 'IFRAME') {
                        const iframeDoc = input.contentDocument || input.contentWindow.document;
                        newVal = iframeDoc.body.innerText;
                    }
                    if (input.type === 'checkbox') {
                        newVal = input.checked ? 1 : 0;
                    }
                    newInitialValues[`input${index}`] = newVal;
                    if (initialValues[`input${index}`] !== newVal) {
                        hasChanges = true;
                    }
                }
            });

            return hasChanges;
        },
        hasParentWithAnyClass(element, classNames) {
            while (element) {
                if (element.classList && classNames.some(className => element.classList.contains(className))) {
                    return true;
                }
                element = element.parentElement;
            }
            return false;
        },
        closeModal(sidebar, checkChanges = false) {
            if (sidebar.$parent.$parent.modalTitle) {
                document.title = 'eRIKA - smart digital solution'
            }
            if (checkChanges && this.checkForChanges(sidebar.$parent.name)) {
                this.$confirm(
                    {
                        message: this.$t('common.confirmations.close'),
                        button: {
                            no: this.$t('common.button.no'),
                            yes: this.$t('common.button.yes')
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.finalCloseModal(sidebar);
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                )
            } else {
                this.finalCloseModal(sidebar);
                return true;
            }
        },
        finalCloseModal(sidebar) {
            let id = sidebar.$parent.name
            if (id == 'kva-modal') {
                id = 'offering-modal'
            }
            this.renderedModals = this.renderedModals.filter(name => name != id)
            if (this.renderedModals.length == 0) {
                document.body.classList.remove("modal-open")
            }
            sidebar.localShow = false
        },
        showJobsToast(jobGroup) {
            this.jobsByGroupResult = {
                'jobs': null,
                'progress': null,
                'done': null,
                'failed': null,
                'success': null,
            }
            this.jobsGroup = jobGroup
            this.checkJobsCount()
            this.setJobsInterval()
            this.jobsToast = true

        },
        checkJobsCount() {
            this.$store.dispatch('Jobs/fetchJobsByGroupStatus', this.jobsGroup)
                .then(() => {
                    let result = this.getJobsByGroup()
                    this.jobsByGroupResult = {
                        'jobs': result.jobs,
                        'progress': result.jobs - (result.done_jobs + result.failed_jobs),
                        'done': result.done_jobs,
                        'failed': result.failed_jobs,
                        'success': result.success,
                    }

                    if (this.jobsByGroupResult.progress == 0) {
                        this.jobsLoading = false
                        clearInterval(this.jobsInterval)
                        this.jobsInterval = null
                    } else {
                        this.jobsLoading = true
                    }
                })
        },
        setJobsInterval() {
            this.jobsInterval = window.setInterval(() => {
                this.checkJobsCount()
            }, 2000)
        },
        setImpersonateInterval() {
            this.impersonateInterval = window.setInterval(() => {
                this.impersonate = this.getImpersonate()
                this.connectionDisabled = this.getExternalConnections()
            }, 1000)
        },
        addEventListeners() {
            document.addEventListener('keydown', function (event) {
                if (event.ctrlKey && event.key === 'k') {
                    event.preventDefault();
                    if (this.renderedModals && this.renderedModals.length <= 0) {
                        document.getElementById('common_search').focus();
                    }
                }
            }.bind(this));
        },
    },
    watch: {
        '$route'(to, from) {
            this.renderedModals = []
            document.body.classList.remove("modal-open")
        },
        '$route.query': {
            handler: function () {
                if (this.$route.query.locale && localStorage.locale != this.$route.query.locale) {
                    localStorage.locale = this.$route.query.locale
                    i18n.locale = localStorage.locale

                    localeChanged()
                    const messages = this.$store.dispatch('I18NStore/fetchMessages')
                    Promise.all([messages])
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        document.body.classList.remove("modal-open")
        this.setImpersonateInterval()
        this.addEventListeners()
    },

}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';

#log_table thead th {
    min-width: auto !important;
}

table#z_index_10 thead th {
    z-index: 10;
}

.doc_info_img {
    max-width: 800px;
    cursor: zoom-in;
}

.td_table_selected .custom-select.is-valid, .td_table_selected .was-validated .custom-select:valid {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23636f83' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") #fff no-repeat right 0.75rem center/8px 10px;
    padding: 0.25rem 1.5rem 0.375rem 0.75rem !important;
    border: none;
}

i.custom-button-previous:before {
    content: "<<";
}

i.custom-button-next:before {
    content: ">>";
}

i.custom-button-clear:before {
    content: "Clear";
}

.card-header strong {
    font-weight: 300;
}

html {
    height: 100%;
}

body {
    display: grid;
    overflow-x: auto;
    /*zoom: 75%;*/
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.c-app {
    min-height: 100%;
}

.b-sidebar {
    min-height: 100%;
}

body.modal-open {
    overflow: hidden;
}

.multiselect__tag {
    margin-top: 5px;
    margin-bottom: 0px !important;
}

.b-table-top-row .multiselect--active {
    z-index: 1029;
}

.b-table-top-row .multiselect__content-wrapper {
    min-width: 300px;
    width: fit-content;
}

.multiselect__tags {
    margin-top: 0px;
    min-height: 35px !important;
    padding-top: 0px !important;
    border: 1px solid rgb(118, 129, 146) !important;
}

.multiselect__placeholder {
    margin-bottom: 2px !important;
    padding-top: 6px !important;
}

.multiselect__select {
    height: 35px !important;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background-color: #321fdb !important;
}

.multiselect__clear:before {
    transform: rotate(45deg);
}

.multiselect__clear:after {
    transform: rotate(-45deg);
}

.multiselect__clear:after, .multiselect__clear:before {
    content: "";
    display: block;
    position: absolute;
    width: 3px;
    height: 16px;
    background: #aaa;
    top: 12px;
    right: 4px;
}

.multiselect__clear {
    position: absolute;
    right: 41px;
    height: 40px;
    width: 40px;
    display: block;
    cursor: pointer;
    z-index: 2;
}

span.multiselect__option {
    min-height: 30px;
    font-size: 12px;
    line-height: 12px;
    padding: 6px;
}

.sidebar-close {
    position: absolute;
    right: 20px;
}

tfoot th {
    background-color: #c6e2ff;
}

tfoot {
    bottom: 0;
    position: sticky;
    box-shadow: inset 0px -1px 0px 2px #fff;
}

.first_tfoot_tr {
    box-shadow: 0px 1px 0px 0px #c6e2ff;
}

.second_tfoot_tr {
    box-shadow: 0px -1px 0px 0px #ced2d8;
}


.zw-sticky .table td {
    overflow-wrap: anywhere;
}

.zw-sticky .table {
    width: min-content;
}

.zw-sticky .table thead th {
    position: sticky;
    top: 75px;
    z-index: 999;
}

table.no-top th {
    top: auto !important;
}

@media (hover: hover), (-ms-high-contrast: none) {
    .table-hover tbody tr:hover {
        color: #3c4b64;
        background-color: rgb(0 0 21 / 13%);
    }
}

.table-responsive {
    overflow: unset;
}

.handle {
    cursor: grab;
}

.cursor-grabbing * {
    cursor: grabbing !important;
}

.b-toaster {
    z-index: 3001 !important;
}

table .thead-info {
    background-color: #c6e2ff;
}

.zoomed-table th, .zoomed-table td {
    /*zoom: 75%;*/
}

.container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.c-main {
    padding-top: 0px;
}

.c-subheader {
    background-color: #3c4b64;
    color: white;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
    color: #fff;
    background: rgba(249, 177, 21, 0.3);
}

.zw-page > div.card-body {
    margin-top: 50px;
    padding-top: 0px;
}

.zw-page > header {
    width: calc(100% - 256px);
    background: lightblue;
    position: fixed;
    z-index: 1001;
}

.page-header {
    background: lightblue;
    padding: 5px 1.25rem;
    line-height: 27px;
}

.zw-page .table-actions {
    background: white;
    z-index: 1000;
    padding-top: 5px;
    margin-left: -22px;
    padding-left: 10px;
}


.zw-sticky .table .b-table-top-row th {
    position: sticky;
    top: 160px;
    background: lightgray;
    z-index: 999;
}

th.position-column span, span.thead_span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin: 0px 0px -5px 0px;
    max-width: 100%;
}

.zw-sticky .table .b-table-top-row th.table_th_multiselect-values {
    z-index: 1000;
}

.multiselect--above .multiselect__content-wrapper {
    bottom: inherit !important;
    border-radius: inherit !important;
    border-bottom: inherit !important;
    border-top: inherit !important;
}

.table td {
    border-right-color: gray;
    border-right-style: dotted;
    border-right-width: thin;
    overflow-wrap: anywhere;
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#table td {
    overflow: hidden;
}

.table thead th {
    font-size: 13px;
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
}

.b-sidebar-body .b-table-sticky-header {
    overflow: visible;
}

ul.dropdown-menu {
    min-width: fit-content;
}

.b-table-top-row th {
    padding: 0.25rem 0.5rem;
}

.table td .body_image {
    max-height: 20px;
}

.b-table-top-row th .custom-select {
    padding-top: 2px;
}

.table td .dropdown {
    position: absolute;
}

.table td .dropdown .dropdown-menu {
    margin-left: -50px;
}

#table {
    width: auto;
}

#table td.td_table_total, #table td.td_table_actions, #table td.td_table_options {
    overflow: visible;
    position: relative;
}

#table[tablename="attribute"] td.td_table_options,
#table[tablename="customFields"] td.td_table_options,
#table[tablename="attribute_option_groups"] td.td_table_options {
    overflow: hidden;
}

.zero_padding_card_body .card-body {
    padding: 0;
}

#table .table td .dropdown {
    top: 5px;
    left: 5px;
}

.b-table-top-row th .multiselect {
    min-height: 30px;
}

.b-table-top-row th .multiselect__tags {
    margin-top: 0px !important;
    min-height: 28px !important;
}

.b-table-top-row th .form-control, .b-table-top-row th .custom-select {
    height: calc(1em + 0.75rem + 2px);
}

.b-table-top-row th button {
    padding: 0.25rem 0.5rem;
}

.table td .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.765625rem;
    line-height: 1;
    border-radius: 0.2rem;
}

.slim_form_group fieldset.form-group {
    margin: 0px;
}

.slim_form_group input {
    height: 30px;
}

.dropdown-menu {
    z-index: 2009;
}

li.list-group-item.logo_create {
    padding: 0px;
    margin: 0px;
    background: transparent;
    position: absolute;
    left: -6px;
    top: -3px;
}

li.list-group-item.logo_create div button {
    background: transparent;
    border: none;
    color: blue;
    font-size: 26px;
}

li.list-group-item.logo_create div button::after {
    display: none;
}

a.c-sidebar-brand span {
    position: relative;
}

li.list-group-item.logo_create div button::after {
    display: none;
}

.c-header {
    min-height: auto;
}

.c-header .list-group-item {
    padding: 3px 5px;
    padding-right: 0px;
}

.zw-page > div.card-body {
    margin-top: 0px;
}

.b-table-top-row th .form-control {
    padding: 0.25rem 0.25rem;
}

.row.table-actions .form-inline button, .row.table-actions .form-inline span,
.row.table-actions .form-inline input, .row.table-actions .form-inline select {
    padding: 0.25rem 0.5rem;
    line-height: 1;
    height: calc(1em + 0.75rem + 2px);
}

.table td .custom-control.custom-checkbox {
    margin-left: 25%;
}

.row.table-actions .form-inline select {
    padding: 0.25rem 1.5rem 0.25rem 0.5rem;
}

.row.table-actions span.badge.mr-2.badge-info {
    padding: 0rem 0.25rem;
}

.row.table-actions button.btn.btn-warning {
    padding: 0.25rem 0.5rem;
}

.row.table-actions button.btn.btn-light {
    padding: 0.25rem 0.5rem;
}

.right_sidebar {
    width: 256px;
    background: rgba(255, 255, 255, 0.8);
}

.b-table-top-row .form-group {
    margin-bottom: 0px;
}

.c-sidebar-maintenance {
    background-color: seagreen;
}

td.is_price_true {
    text-align: right;
}

.impersonate-warning {
    z-index: 9999999;
    bottom: 0px;
    position: fixed;
    width: 100%;
}

.is-valid {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23636f83' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") #fff no-repeat right 0.75rem center/8px 10px !important;
    border-color: rgb(118, 129, 146) !important;
}

.form-control, .custom-select {
    border-color: rgb(118, 129, 146) !important;
}

.page-link {
    border-color: rgb(118, 129, 146) !important;
}

div.bootstrap-datetimepicker-widget table td {
    overflow: visible !important;
    overflow-wrap: normal !important;
    white-space: normal;
}

.b-sidebar th.table_th_date .form-inline {
    position: sticky;
}

.c-sidebar .c-sidebar-brand {
    background: none;
}

.c-sidebar-brand {
    flex: 0 0 47px;
}

.c-sidebar-brand img {
    height: 40px;
}

.c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle {
    padding-bottom: 5px;
    padding-top: 5px;
}

.dropdown-item {
    padding-bottom: 4px;
    padding-top: 4px;
}

.card.zw-page {
    margin-bottom: 0px;
}

.card.zw-page .card-body {
    padding-bottom: 0px;
}

.c-footer {
    flex: 0 0 25px;
}

.hot_key_placeholder {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #aaa;
    pointer-events: none;
    z-index: 3;
}

.form-actions {
    margin-top: 45px;
}

.form-actions .row {
    position: absolute;
    background-color: lightgoldenrodyellow;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    width: 100%;
    bottom: 0px;
    left: 17px;
    z-index: 10;
}

.custom-card-title {
    position: absolute;
    top: -9px;
    left: 10px;
}

.placeholders-card {
    margin-bottom: 10px;
}

.placeholders-card .card-body {
    padding: 5px 10px;
}
</style>