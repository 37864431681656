<template>
    <div>
        <component v-for="(component,index) in vueComponents"
                   :key="index"
                   v-bind:is="component"
                   :base="base"
        ></component>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    props: {
        'id': {
            type: String,
            required: true
        },
        'base': {
            type: Object,
        },
    },
    data() {
        return {
            vueComponents: [],
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getPlugins']),
    },
    mounted() {
        setTimeout(() => {
            this.getPlugins().forEach(pluginName => {
                if (window[pluginName] && window[pluginName].methods.getCustomSlot) {
                    window[pluginName].methods.getCustomSlot(this.id).forEach((element) => {
                        this.vueComponents.push(window[pluginName].components[element])
                    });
                }
            })
        }, 2000);

    },
}
</script>